<template>
    <li v-if="icon" :id="deviceId !== '' ? deviceId : name" class="nav-item nav-dropdown">
        <div class="nav-link nav-dropdown-toggle" @click="handleClick">
            <fa-icon v-if="this.icon" :icon="this.icon" class="nav-icon" style="color: #a0a0a0;"/>{{ name }}
        </div>
        <ul class="nav-dropdown-items">
            <slot></slot>
        </ul>
    </li>
    <li v-else :id="deviceId !== '' ? deviceId : name" class="nav-item nav-dropdown" style="background-color: inherit">
        <div class="d-flex align-items-center rounded highlightEle" style="width: fit-content; cursor: pointer" @click="handleClick">
            <i :id="(deviceId !== '' ? deviceId : name) + 'icon'" class="fas fa-folder" style="color: #ffffb5"/>
            <div>&nbsp;{{name}}</div>
        </div>
        <ul class="nav-dropdown-items">
            <slot></slot>
        </ul>
    </li>

</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        deviceId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isHovered: false
        }
    },
    mounted() {
        if (this.name === 'Video Wall') {
            document.getElementById('Video Wall').classList.toggle('open');
        }
    },
    methods: {
        handleClick(e) {
            this.$emit('dropDownClicked');
            if (this.icon) {
                e.preventDefault();
                e.target.parentElement.classList.toggle('open');
            } else {
                e.preventDefault();
                e.target.parentElement.parentElement.classList.toggle('open');
                let element = document.getElementById((this.deviceId !== '' ? this.deviceId : this.name) + 'icon').classList;
                if (document.getElementById((this.deviceId !== '' ? this.deviceId : this.name) + 'icon').classList.contains('fa-folder')) {
                    element.remove('fa-folder');
                    element.add('fa-folder-open');
                } else {
                    element.add('fa-folder');
                    element.remove('fa-folder-open');
                }
            }
        }
    },
    computed: {
        classIcon() {
            return [
                'nav-icon',
                this.icon
            ];
        },
        isOpen() {
            if (document.getElementById((this.deviceId !== '' ? this.deviceId : this.name) + 'icon') !== null) {
                return document.getElementById((this.deviceId !== '' ? this.deviceId : this.name) + 'icon').classList.contains('fa-folder') === false;
            }
            return false;
        }
    },
}
</script>

<style scoped lang="css">
.nav-link {
    cursor: pointer;
}

.sidebar .nav-dropdown.open > .nav-dropdown-items {
    max-height: none;
}

.sidebar-minimized .sidebar .nav > .nav-dropdown.open > .nav-dropdown-items {
    max-height: none;
}
.highlightEle:hover {
    background-color: #595959;
}
</style>
